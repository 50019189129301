@font-face {
    font-family:   Plus Jakarta SansVariable;
    font-style: normal;
    font-display: swap;
    font-weight: 200 800;
    src: url(../fonts/plus-jakarta-sans-latin-variable-wghtOnly-normal.67354a73.woff2)  format("woff2");
}

@font-face {
    font-family: Plus Jakarta SansVariable;
    font-style: normal;
    font-display: swap;
    font-weight: 200 800;
    src: url(../fonts/plus-jakarta-sans-latin-variable-wghtOnly-normal.67354a73.woff2) format("woff2");
}

@font-face {
    font-family: Plus Jakarta SansVariable;
    font-style: normal;
    font-display: swap;
    font-weight: 200 800;
    src: local('tset') , 
    url(../fonts/plus-jakarta-sans-latin-variable-wghtOnly-normal.67354a73.woff2) format("woff2");
}

@font-face {
    font-family: Plus Jakarta SansVariable;
    font-style: normal;
    font-display: swap;
    font-weight: 200 800;
    src: url(../fonts/plus-jakarta-sans-latin-variable-wghtOnly-normal.67354a73.woff2) format("woff2");
}

@font-face {
    font-family: Noto Sans ArabicVariable;
    font-style: normal;
    font-display: swap;
    font-weight: 100 900;
    src: url(../fonts/noto-sans-arabic-arabic-variable-wghtOnly-normal.3e70b656.woff2) format("woff2");
}
* , *::after , *::before{
    padding: 0px;
    margin: 0px;
    box-sizing: inherit;
    text-decoration: none;
}
html {
    box-sizing: border-box;
    font-size: 62.5%;
    overflow-x: hidden;
}
body {
    position: relative;
    width: 100%;
    overflow-x: hidden;
}
/* haeder */
.header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.header-nav {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 34%;
    border-bottom: 2px #00000012 solid;
    z-index: 10;
    background-color: #FfF;
    animation-fill-mode: both;
}
.header-nav-btns {
    display: inline-flex;
    height: 32.544px;
    align-items: center;
    gap: 12.726px;
}
.header-start-btn{
    border-radius: 8px;
    width: 44.07px;
    height: 32.644px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #4C4DDC;
    color: #FFF;
    font-size: 16px;
    font-family:"Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight:500;
    line-height: 15.777px;
}
/* .header-sing-in-btn {
    width: 98.75px;
    height: 32.644px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6.529px;
    border: 0.816px solid #D3D9E5;
    color:  #121C30;
    text-align: center;
    font-size: 12.621px;
    font-family:"Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 500;
    line-height: 15.777px;
} */
.header-nav-language {
    display: flex;
    align-items: center;
    gap: 4.454px;
    text-decoration: none;
    color:  #121C30;
    font-size: 12.621px;
    font-family:"Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 15.777px;
    text-transform: uppercase;
    color: #000;
}
.header-nav-language span{
    display: flex;
    justify-content: center;
    gap: 3px;
}
.haeder-nav-menus{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 36px;
}
.haeder-nav-menu{
    display: inline-flex;
    height: 19.66px;
    align-items: flex-start;
    gap: 18.143px;
}
.header-nav-menu a , .header-nav-menu i {
    text-decoration: none;
    color:  #121C30;
    font-size: 13px;
    font-family:"Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 19.327px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.haeder-nav-logo {
    width: 120px;
}
@media (max-width : 1200px) {

}
@media (max-width : 1080px) {
    .header-nav {
       padding-inline: 20px;
        justify-content: space-between;
        gap: 0px;
    }
}
@media (max-width : 700px) {
    .header-nav {
        padding-inline: 15px 10px ;
    }
    .haeder-nav-menus , .header-nav-btns{
        display: none;
    }
    .mobile-nav , .header-nav{
        background-color: #fff;
        position: sticky;
        top: 0;
        width: 100%;
       display: flex !important;
       justify-content: space-between;
       align-items: center;
       z-index: 2;
    }
    .mobile-nav i {
        font-size: 20px;
    }
    .mobile-start-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;
    }
    .header-start-btn {
        width: 0px !important;
        height: 0px;
        padding:20px 25px ; 
    }
}
.header-main {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:25px 0px 100px ;
}
.header-clip{
    top: 0px;
    left: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #4C4DDC;
    clip-path: polygon(0 54%, 100% 85%, 100% 100%, 0% 100%);
    z-index: -1;
}
.header-contant-img {
    width: 70%;
    height: 634.609px;
    display: flex;
    justify-content: space-between;
    gap: 5%;
    flex-wrap: wrap;
    align-items: center;
    margin-inline: 50px;
}
.header-iphone {
    width: 283.983px;
    height: 574.609px;
}
.haeder-contant {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 27.642px;
}
.header-title {
    width: 500px;
    color: var(--background-font, #121C30);
    text-align: right;
    font-size: 36px;
    font-family:  "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
}

.header-text{ 
    width: 440.963px;
    color: #1A202C;
    text-align: right;
    font-size: 16.566px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 24.282px;
}
.header-video {
    display: flex;
    align-items: center;
    gap: 7.898px;
}
.header-play-btn {
    width: 40.231px;
    height: 40.231px;
    border-radius: 50%;
    background-color: #4C4DDC;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16px;
}
.header-video-text {
    color: #121C30;
    text-align: center;
    font-size: 16.566px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 500;
}
.header-btns {
    display: flex;
    align-items: flex-start;
    gap: 16.197px;
}
.header-btns div {
    width: 195.577px !important;
    height: 61.599px !important;
}
.qrcode-container {
    width: 62px !important;
    height: 62px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    border-radius: 17.5px;
}
.qrcode-container svg {
    width: 30px;
    height: 30px;
}
@media (max-width:1160px) {
    .header-contant-img {
        justify-content: center;
    }
    .header-iphone {
        order: 2;
    }
    .haeder-contant {
        align-items: center;
        gap: 27.642px;
    }
    .header-title {
        line-height: 43.707px;
    }
    .header-text , .header-title{ 
        width: 100%;
        text-align: center;
    }
    .header-contant-img {
        height: fit-content;
        gap: 100px;
    }
}
@media (max-width:550px) {
    .header-btns div , .header-btns img {
        width: 90% !important;
        height: 50px !important;
    }
    .qrcode-container {
        display: none;
        }
        .qrcode-container svg {
            width: 20px;
        }
}
@media (max-width:475px) {
    .header-main{
        padding: 35px 0px 77px;
    }
    .header-iphone {
        height: 394.609px;
    }
    .haeder-contant {
        width: 100%;
        align-items: center;
        gap: 25px;
    }
    .header-title {
        width:100%;
        font-size: 27.287px;
    }
    .header-text { 
        font-size: 15px;
    }
    .header-video-text {font-size: 16px;}
    .header-play-btn {
        width: 35px;
        height: 35px;
        font-size: 12px;
    }
    .header-contant-img {
        width: 100%;
        height: fit-content;
        gap: 35px;
        margin:0px  15px;
    }
    .header-iphone {
        height: fit-content;
    }
    .header-clip{
        clip-path: polygon(0 54%, 100% 65%, 100% 100%, 0% 100%);
    }
}
.grid{
    width: 100%;
    padding-bottom: 43px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #F5F7F9;
}
.grid-cards {
    display: grid;
    grid-template-columns: repeat(4,minmax(0,1fr));
    gap:82px ;
    padding: 21px;
    border-radius: 6.754px;
    border: 0.844px solid #EBEEF4;
    background: #FFF;
    box-shadow: 0px 0.21107704937458038px 0.8443081974983215px 0px rgba(0, 0, 0, 0.04);
    transform: translatey(-50%);
}
.grid-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8.677px;
}
.grid-card-title {
    color: #1A202C;
    text-align: center;
    font-size: 28.398px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 700;
    line-height: 36.474px;
}
.grid-card-text {
    color: #121C30;
    text-align: center;
    font-size: 12.621px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 24.253px;
}
.grid-contant {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 7.888px;
}
.grid-logo {
    width: 25.243px;
    height: 25.243px;
}
.grid-logo-link{
    position: relative;
    background: linear-gradient(155deg, #5E17EB 0%, #000 49.48%, #5E17EB 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 16px */
}
.grid-logo-link::after{
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0pc;
    width: 100%;
    height: 1px;
    background-color: #000;
}
.grid-logo-text {
    color: #121C30;
    text-align: center;
    font-size: 15.777px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 24.253px;
}
@media (max-width : 950px) {
    .grid-cards {
        display: grid;
        grid-template-columns: repeat(2,minmax(0,1fr));
        gap:40px ;
        transform: translatey(-10%);
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
}
@media (max-width : 450px) { 
    .grid{
        position: relative;
        height: fit-content;
        gap: 0px;
        padding-top: 200px;
        justify-content: flex-end;
        }
    .grid-cards {
        position: absolute;
        padding: 30px 0px;
        width: 93%;
        grid-template-columns: repeat(1,minmax(0,1fr));
        transform: translatey(-12%);
    }
    .grid-card-title {
        font-size: 30.398px;
        line-height: 30.474px;
    }
    .grid-card-text {
        font-size: 13.621px;
        line-height: 20.253px;
    }
    .grid-contant {
        padding-top: 20px;
    }
    .grid-logo {
        width: 20.243px;
        height: 20.243px;
    }
    .grid-logo-text {
        font-size: 10.777px;
        line-height: 20.253px;
    }


}

.method{
    padding: 70px 0px 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:40px ;
}
.method-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.method-title {
    color: #1A202C;
    text-align: center;
    font-size: 37.864px;
    font-family:  "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 700;
    line-height: 58.207px;
}
.method-text {
    color: #1A202C;
    text-align: center;
    font-size: 12.621px;
    font-family:  "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 500;
    line-height: 24.253px;
}
.method-cards {
    display: grid;
    grid-template-columns: repeat(4,minmax(0,1fr));
    gap:100px;
}
.method-card{
    opacity: 0.5;
    display: flex;
    width: fit-content;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 15.777px;
    transition: 0.3s cubic-bezier(0.77, 0, 0.175, 1);
}
.method-card:has(#idActive){
    opacity: 1 ;
}
.method-card-tag {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 20px;
}
.method-card-id {
    width: 28.398px;
    height: 24.454px;
    border-radius: 4.047px;
    color: #000;
    text-align: center;
    font-size: 11.044px;
    font-family:  "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 700;
    line-height: 21.247px;
    border-radius: 4.047px;
    border: 0.789px solid var(--second-color-font, #A3ABBA);
    cursor: pointer;
    transition: 0.3s cubic-bezier(0.77, 0, 0.175, 1);
}
#idActive {
    background-color: #4C4DDC;
    color: #fff ; 
}
.method-card-title {
    color: #121C30;
    text-align: right;
    font-size: 15.777px;
    font-family:  "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 600;
    line-height: 24.282px;
}
.method-card-text {
    color: var(--background-font, #121C30);
    text-align: right;
    font-size: 12px;
    font-family:  "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    width: 205px;
}
[card~="3"] .method-card-text {
    width:220px;
}
[card~="4"] .method-card-text {
    width: 220px;
}
@media (max-width:1091px) { 
    .method-cards {
        display: grid;
        grid-template-columns: repeat(2,minmax(0,1fr));
        gap:30px;
    }
    [card = "1"]{grid-area: 1/1;}
    [card = "2"]{grid-area: 1/2;}
    [card = "3"]{grid-area: 2/1;}
    [card = "4"]{grid-area: 2/2;}
}
@media (max-width : 450px) { 
    .method {
        padding: 40px 0px 130px;
        gap: 20px;
    }
    .method-cards {
       display: none;
    }
    .method-card-small {
        display: flex !important;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
        padding-inline:15px 10px;
        gap: 15px;
        text-align: right;
    }
    .method-card-small-header{
        align-self: center;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }
    .method-card-small-header h1 {
        color: #00000078;
        font-size: 14px;
        padding:3px 14px;
        font-weight: bold;
        font-family:   "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    }
    #smallactive{
        background-color: #4C4DDC;
        color: #fff;
        border-radius: 4px;
        transition: 0.3s ease-in-out;
    }
    .method-small-title {
        font-size: 17px;
        font-family:   "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
        line-height: 26px;
        font-weight: 500;
    }
    .small-card-text{ 
        font-size: 14.3px;
        font-family:   "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
        line-height: 22px;
        font-weight: 350;
        direction: rtl;
    }
    .method-header{
        gap: 13px;
    }
    .method-title {
        font-size: 31px;
        line-height: 48.207px;
    }
    .method-text {
        width: 340px;
        font-size: 16px;
        line-height: 24px;
    }

}

.iphone {
    position: relative;
    left: 0%;
    width: 400%;
    height: 379.433px;
    /* padding-right:200px; */
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    background: var(--background, #121C30);
    animation:  1s  ease-in-out;
    animation-fill-mode: forwards;
}
@keyframes goLeft1 {
   from {}to{left: 0%;}
}
@keyframes goLeft2 {
    from {}to{left: -100%;}
 }
 @keyframes goLeft3 {
    from {}to{left: -200%;}
 }
 @keyframes goLeft4 {
    from {}to{left: -300%;}
 }
.iphone-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 250px;
}
.iphone-contant {
    width: 363.794px;
    height: 206.15px;
    border-radius: 8.084px;
    background: #FFF;
    box-shadow: 0px 1.5158072710037231px 2.2737112045288086px 0px rgba(0, 0, 0, 0.04);
    padding: 23px 22px 0px ;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    transform: translate(30px ,-82px );
    z-index: 2;
}
.iphone-contant-border {
    width: 40.422px;
    height: 4.042px;
    border-radius: 10104.373px;
    background:  #4C4DDC;
}
.iphone-text {
    width: 314.277px;
    color: var(--background, #121C30);
    text-align: justify;
    font-size: 16px;
    font-family:  "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 30.745px;
}
.iphone-img {
    width: 284.209px;
    height: 575.066px;
    transform: translate(0px , -40px);
}
.iphone-broswer{
    width: 108.071px;
    height: 108.071px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6.754px;
    background: #D4E0FF;
    box-shadow: 0px 1.2664623260498047px 1.899693489074707px 0px rgba(0, 0, 0, 0.04);
    transform: translateX(-18px)
}
@media (max-width:1000px) {
    .iphone-container {
        padding-right: 0px;
    }
}
@media (max-width : 900px) {
    .iphone {
        padding-right:0px;
    }
    .iphone-contant  , .iphone-broswer{
        display: none;
    }

}
@media (max-width : 500px) {
    .iphone {
        position: relative;
        height: 300px;
        background-color: transparent;
    }
    .iphone-container {
        padding-right: 0px;
    }
    .iphone::after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 95%;
        background-color: #121C30;
        z-index: -1;
    }
    .iphone-img {
        height: 600px;
        transform: translate(0px , -40px);
    }
}
.investment {
    width: 100%;
    padding: 58px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:40px ;
}
.investment-header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.investment-title{
    color: #121C30;
    text-align: center;
    font-family:   "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 58.207px;
}
.investment-text{
    direction: rtl;
    width: 90%;
    color: #1A202C;
    text-align: center;
    font-size: 16px;
    font-family:   "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 20.263px;
}
.investment-grid {
    display: grid;
    grid-template-columns: repeat(4,minmax(0,1fr));
    column-gap:33.131px ;
}
.investment-card {
    width: 235.075px;
    height: 231.92px;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4.733px;
    padding: 13px 21px 2px 26px;
    border-radius: 6.754px;
    border: 0.844px solid #EBEEF4;
    background: #FFF;
    box-shadow: 0px 0.25px 1px 0px #0000000A,0px 0.6px 2.5px 0px #00000007,0px 1.15px 4.75px 0px #00000006,0px 2px 8.5px 0px #00000005,0px 3.75px 15.85px 0px #00000004,0px 9px 38px 0px #00000003;
}
.investment-card-icon {
    width: 50.486px;
    height: 50.486px;
}
.investment-card-contant {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 11.833px;
}
.investment-card-title {
    color:  #121C30;
    text-align: right;
    font-size: 15.777px;
    font-family:   "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 600;
    line-height: 29.138px;
}
.investment-card-text {
    color:  #121C30;
    text-align: right;
    font-size: 12.621px;
    font-family:   "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 20.263px;
    direction: rtl;
}
@media (max-width: 1100px) {
    .investment-grid {
        display: grid;
        grid-template-columns: repeat(2,minmax(0,1fr));
        gap:20px ;
    }
    .investment-card {
        width: 215.075px;
    }
}
@media (max-width: 500px) {
    .investment {
        width: 100%;
        padding:48px 0px;
        gap:50px ;
    }
    .investment-header{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }

    .investment-title{
        width: 100%;
        font-size: 30px;
        line-height: 48.207px;
    }
    .investment-text {
        width: 100%;
        font-size: 16px;
        line-height: 25px;
        direction: rtl;
    }
    .investment-grid {
        width: 95%;
        display: grid;
        grid-template-columns: repeat(1,minmax(0,1fr));
        gap:15px ;
    }
    .investment-card {
        width: 100%;
        height: fit-content;
        padding: 25px 21px 35px 26px;
    }
    .investment-card-icon {
        margin-bottom: 10px;
    }
    .investment-card-title {
        font-size: 23px;
    }
    .investment-card-text { 
        font-size: 13px;
    }
}


.testimonial{
    position: relative;
    padding: 85px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:19px ;
    flex-direction: column;
    background-color: #F6F7F9;
    z-index: -1;
}
.testimonial-main{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 127.793px;
}
.testimonial-title {
    color: #1A202C;
    text-align: right;
    font-size: 37.864px;
    font-family:   "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 700;
    line-height: 45.437px;
    width: 418.875px;
}
.testimonial-card {
    width: 485.927px;
    height: 234.286px;
    border-radius: 9.466px;
    background: #FFF;
    padding:26px 27px 32px ;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 23.665px;
}
.testimonial-card-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 155px;
}
.testimonial-card-title {
    color: #1A202C;
    text-align: right;
    font-size: 14.199px;
    font-family:   "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 600;
    line-height: 17.039px; 
}
.testimonial-card-text {
    color: #5E6473;
    text-align: right;
    font-size: 11.044px;
    font-family:"Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 16.566px;
}
.testimonial-reviews {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6.311px;
}
.reviews-stars {
    display: flex;
    align-items: flex-start;
    gap: 6.311px;
    list-style: none;
}
.star {
    font-size: 12.621px;
    color: #4C4DDC;
}
.reviews-date {
    color: #5E6473;
    font-size: 10.255px;
    font-family:"Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 16.566px;
}
.user-name {
    color: #1A202C;
    text-align: right;
    font-size: 11.044px;
    font-family:"Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 16.566px;
}
.testimonial-btns{
    display: inline-flex;
    align-items: flex-start;
    gap: 18.932px;
}
.testimonial-btns span{ 
    width: 60px;
    height: 60px;
    border-radius: 7887.638px;
    border: 0.789px solid #D3D9E5;
    background: #F6F7F9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18.932px;
    color: #000;
    cursor: pointer;
    margin-top: 30px;
}
.testimonial-background{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: #121C30;
    clip-path: polygon(0 0, 30% 0, 50% 100%, 0% 100%);
    z-index: -1;
}
@media (max-width: 1100px) {
    .testimonial-main{
        flex-wrap: wrap;
        gap: 57.793px;
        padding-inline: 20px;
    }
    .testimonial-title {
        font-size: 30.864px;
        line-height: 40.437px;
        width: 300px;
    }
    

}

@media (max-width: 884px) {
    .testimonial-background{
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
        height: 100%;
        background-color: #121C30;
        clip-path: polygon(0 52%, 100% 65%, 100% 100%, 0% 100%);
        z-index: -1;
    }
    .testimonial-main{
        flex-direction: column-reverse;
    }
    .testimonial-title {
        text-align: center;
        width: 80%;
    }
    .testimonial-card {
        border: 1px solid #121C30;
    }
}
@media (max-width: 550px) { 
    .testimonial {
        padding: 34px 0px;
    }
    .testimonial-main{
        align-items: center;
        justify-content: center;
        gap: 30px;
        padding: 0px;
    }
    .testimonial-title {
        width: 70%;
        font-size: 25px;
    }
    .testimonial-card {
        width: 95%;
        height: fit-content;
        border: none;
        gap: 25px;
    }
    .testimonial-card-header {
        gap: 20px;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .testimonial-reviews {
        flex-direction: row-reverse;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    .testimonial-card-title {
        font-size: 18px;
        line-height: 25px;
        font-weight: 700;
    }
    .testimonial-card-text {
        font-size: 14px;
        line-height: 23px;
    }
    .reviews-date  {
        font-size: 14px;
    }
    .reviews-stars li{ 
        font-size: 16px;
    }
    .card-container{
        justify-content: center;
        align-items: center;
    }
    .testimonial-btns span{margin-top: 10px;}
}
    /* /section calculator/  */
.calculator{
    width: 100%;
    padding: 62px 20px 77px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    align-items: center;
}
.calculator-title {
    color: #1A202C;
    text-align: center;
    font-size: 28.398px;
    font-family:"Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 700;
    line-height: 34.078px;
}
.calculator-body {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 37.864px;
    padding: 0px 10px;
}
.calculator-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 23.665px;
}
.calculator-left-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.calculator-left-title {
    color: var(--style, #6B7284);
    text-align: center;
    font-size: 18.932px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 28.398px;
}
.calculator-left-number {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12.621px;
}
.calculator-price {
    color: #121C30;
    text-align: center;
    font-size: 18.932px;
    font-family:"Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 600;
    line-height: 28.398px;
}
.calculator-y {
    color: var(--style, #6B7284);
    text-align: right;
    font-size: 18.932px;
    font-family:"Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 28.398px;
}
.calculator-data-input {
    border-radius: 6.311px;
    border: 0.789px solid #D3D9E5;
    padding: 10px 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 62.319px;
}
.calculator-one-data{
    height: 35.144px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.14px;
}
.ping-name{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 7px;
}
.ping{
    width: 9.466px;
    height: 9.466px;
    border-radius: 7887.638px;
    background: var(--basis, #4C4DDC);
}
.calculator-ping-name {
    color: var(--style, #6B7284);
    text-align: right;
    font-size: 11.044px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 16.566px;
}
.calculator-ping-number{
    color: #1A202C;
    text-align: right;
    font-size: 11.044px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 700;
    line-height: 16.566px;
}
.calculator-data-output {
    display: flex;
    align-items: center;
    gap: 66.263px;
}
.calculator-data-output {
    display: flex;
    align-items: flex-start;
    gap: 60.263px;
    color: var(--background, #121C30);
    text-align: right;
    font-size: 9.466px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 14.199px;
}
.calculator-main-axis {
    width: 319.245px;
    height: 186.54px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 7.3px;
}
.main-axis-charts {
    width: 319.245px;
    height: 167.235px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 23.9px;
}
.chart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0pc;

}
.black-chart {
    width: 44.727px;
    height: 39.818px;
    border-bottom-left-radius: 7.9px;
    border-bottom-right-radius: 7.9px;
    background-color: #121C30;
}
.green-chart {
    width: 44.727px;
    background-color:#41CE8E ;
}
.purple-chart {
    width: 44.727px;
    border-top-left-radius: 7.9px;
    border-top-right-radius: 7.9px;
    background-color:#4C4DDC ;
}
.main-axis-number{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 43px;
}
.main-axis-item{
    color: #101010;
    text-align: center;
    font-size: 9.466px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
}
.calculator-cross-axis{
    display: flex;
    flex-direction: column;
    gap: 17.355px;
}
.calculator-right{
    width: 486.716px;
    height: 361.29px;
    border-radius: 6.311px;
    border: 0.789px solid #D3D9E5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 49.7px;
    padding:24px 19px ;
}
.calculator-right-title {
    color: #1A202C;
    text-align: right;
    font-size: 15.777px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 600;
    line-height: 18.932px;
}
.calculator-right-ranges{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 19.721px;
}
.range {
    width: 447.274px;
    height: 40.231px;
    display: flex;
    flex-direction: column;
    gap: 9px;
}
.range-header {
    display: inline-flex;
    align-items: flex-start;
    gap: 208.971px;
}
.range-title{
    width: 300px;
    text-align: right;
    color: var(--style, #6B7284);
    font-size: 11.833px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 18.932px;
}
.range-number{
    width: 150px;
    text-align: left;
    color: #1A202C;
    font-size: 12.21px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 18.932px;
}
/* input[type=range] {
     transform: rotate(180deg);
    width: 447.274px;
    height: 6.311px;
    border-radius: 7887.638px;
    accent-color: #4C4DDC; 
     direction: rtl; 
} */
.calculator-right-footer{
    width: 90%;
    color: #6B7284;
    text-align: right;
    font-size: 9.466px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 500;
    line-height: 14.199px;
    direction: rtl;
}

@media (max-width : 1030px) {
    .calculator-left{
        order: 2 ;
    }

}
@media (max-width : 530px) { 


    .calculator{
        padding: 50px 0px ;
        gap: 40px;
    }
    .calculator-body {
        padding: 0px 3px;
        gap: 55px;
    }
    .calculator-title {
        font-size: 31px;
    }
    .calculator-left {
        width: 100%;
        gap: 30px;
    }
    .calculator-left-header {
        gap: 10px;
    }
    .calculator-left-number{
        gap: 20px;
    }
    .calculator-left-title {
        font-size: 25px;
        font-weight: 400;
    }
    .calculator-left-number {
        gap: 10px;
    }
    .calculator-price {
        font-size: 25px;
    }  
    .calculator-y {
        font-weight: 400;
        font-size: 24px;
    }
    .calculator-data-input {
        width: 95%;
        padding: 13px 20px;
        flex-direction: column;
        gap: 10px;
    }
    .calculator-one-data{
        width: 100%;
        height: fit-content;
        padding: 0px;
        display: flex;
        flex-direction: row-reverse;
        justify-content:space-between;
        align-items: center;
    }
    .calculator-ping-number{
        font-size: 14px;
    }
    .calculator-ping-name {
        font-weight: 300;
        font-size: 14px;
    }
    .calculator-data-output {
    gap: 0px;
    }
    .calculator-data-output {
        gap: 40.263px;
    }
    .calculator-main-axis {
        width: fit-content;
        height: 186.54px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 7.3px;
    }
    .main-axis-charts {
        width: fit-content;
        height: 167.235px;
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        gap: 10px;
    }
    .chart {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0pc;
    
    }
    .black-chart {
        width: 36px;
        border-bottom-left-radius: 2.9px;
        border-bottom-right-radius: 2.9px;
    }
    .green-chart {
        width: 36px;
    }
    .purple-chart {
        width: 36px;
        border-top-left-radius: 2.9px;
        border-top-right-radius: 2.9px;
    }
    .main-axis-number{
        gap: 22px;
    }
    .calculator-right{
        width: 95%;
        height: fit-content;
        border: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 40px;
        padding:20px  0px;
    }
    .calculator-right-title {
        width: 100%;
        color: #1A202C;
        text-align: right;
        font-size: 20.777px;
        font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 20px;
    }
    .calculator-right-ranges{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 45px;
        width: 100%;
    }
    .range {
        width: 100%;
        height: 40.231px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 30px;
    }
    .range-header {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 0px;
    }
    .range-title{
        width: fit-content;
        text-align: right;
        color: var(--style, #6B7284);
        font-size: 16px;
        font-weight: 300;
        font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
        line-height: 18.932px;
    }
    .range-number{
        width: fit-content;
        text-align: left;
        color: #1A202C;
        font-size: 16px;
        font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
        line-height: 18.932px;
    }
    input[type=range] {
        transform: rotate(180deg);
        width: 100%;
        height: 6.311px;
        border-radius: 7887.638px;
        accent-color: #4C4DDC;
    }
    .calculator-right-footer{
        width: 100%;
        color: #6B7284;
        text-align: right;
        font-size: 12px;
        font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
        font-weight: 400;
        line-height: 19px;
    }
}


.projects {
    position: relative;
    padding:62px 0px 43px ;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 45px ;
    background: #F6F7F9;
    flex-wrap: wrap;
}
.projects-header {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 14.199px;
}
.projects-title {
    color: #1A202C;
    text-align: center;
    font-size: 28.398px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 34.078px;
    font-weight: 600;
}
.projects-text {
    width: 571.122px;
    color: var(--background-font, #121C30);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
}
.projects-images {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 75px;
}
.project-img , .project-card {
    position: relative;
    width: 308.018px;
    height: 422.03px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.project-btns {
    position: absolute;
    top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:93px ;
    z-index: 2;
}
.project-data {
    display: flex;
    width: 155.388px;
    align-items: flex-start;
    gap: 8.275px;
}
.project-loction , .project-type {
    width: 72.637px;
    height: 28.503px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: #121C30;
    text-align: center;
    font-size: 13.046px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 700;
    line-height: 150%;
    background-color: #FFFFFF;
    border-radius: 90px;
}
.project-like {
    width: 36.778px;
    height: 36.778px;
    background-color: #FFF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
}
.Browse-btn {
    width: 178.278px;
    height: 37.864px;
    flex-shrink: 0;
    border-radius: 6.311px;
    border: 0.789px solid #D3D9E5;
    color: #121C30;
    font-size: 14.199px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 600;
    line-height: 17.039px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 11px;
}
.projects-left-btn , .projects-right-btn{
    width: 47.331px;
    height: 18.932px;
    flex-shrink: 0;
    border-radius: 7887.638px;
    border: 0.789px solid #D3D9E5;
    background: #F6F7F9;
    font-size: 12.932px;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}
.projects-left-btn {
    position: absolute;
    left:30px;
    top: 50%;
}
.projects-right-btn{
    position: absolute;
    right: 30px;
    top: 50%;
}
@media (max-width:1250px) {
    .projects-images {
 
    }
    .project-img , .project-card{
        width: 300px;
        height: 322.03px
    }
    .fade-container-three{
        display: none !important;
    }
}
@media (max-width:850px) {
    .projects {
        gap: 30px;
    }
    .projects-header {
        width: 95%;
        gap: 20px;
    }
    .projects-title {
        font-weight: 800;
    }
    .projects-text{
        width: 98%;
        font-size: 16px;
        line-height: 23px;
        direction: rtl;
    }
    .projects-images {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0px;
    }
    .project-img , .project-card{
        width:250px;
        height: 272.03px;
        margin-inline: 20px;
    }
    .fade-container-two{
        display: none !important;
    }
    .project-btns {
        position: absolute;
        top: 25px;
        left: 30px;
        gap:33px ;
    }
    .project-loction , .project-type {
        width: 52.637px;
        height: 25.503px;
        font-size: 7.046px;
    }
    .project-like {
        width: 26.778px;
        height: 26.778px;
        font-size: 13px;
    }
    .projects-title {
        /* font-size: 20.398px; */
        line-height: 24.078px;
    }
    .Browse-btn {
        margin-top: 60px;
    }
    .projects-left-btn , .projects-right-btn{
        width: 50px;
        height: 50px;
        font-size: 12.932px;
    }

    .projects-left-btn {
        position: absolute;
        left:30%;
        top: 75%;
    }
    .projects-right-btn{
        position: absolute;
        right: 30%;
        top: 75%;
    }
}
@media (max-width : 450px ) {
    .fade-container-one ,.fade-container-one .react-reveal{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .fade-container-one .project-card  , .project-img, .project-card{
        width: 100%;
        height: auto;
    }
     .project-btns{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        top: 40px;
     }
     .projects-right-btn ,.projects-left-btn{
        top: 82%;
     }
}

.bounty {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 74px;
    padding: 55px 20px 69px;
    flex-wrap: wrap;
}
.bounty-card{
    border-radius: 6.311px;
    border: 0.789px solid #EBEEF4;
    padding: 25px;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 37.864px;
}
.bounty-header {
    display: flex;
    align-items: center;
    gap: 255.585px;
}
.bounty-logo {
    width: 69.418px;
    height: 69.418px;
}
.bounty-card-contant{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5.522px;
}
.bounty-card-text{
    color: #121C30;
    text-align: right;
    font-size: 12.621px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 500;
    line-height: 18.932px;
}
.bounty-card-title{
    color: #121C30;
    text-align: right;
    font-size: 18.932px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 600;
    line-height: 22.719px;
}
.bounty-list{
    list-style: none;
    display: flex;
    width: 251.641px;
    flex-direction: column;
    align-items: flex-end;
    gap: 18.932px;
}
.bounty-list-item{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5.522px;
}
.bounty-list-item h3 {
    color: #121C30;
    text-align: right;
    font-size: 14.988px;
    font-family:"Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 23.665px;
}
.bounty-right{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 48.119px; 
}
.bounty-contant{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 14.988px;
}
.bounty-title{ 
    width: 311.593px;
    color: #1A202C;
    text-align: right;
    font-size: 37.864px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 700;
    line-height: 45.437px;
}
.bounty-text{
    width: 304.979px;
    color: #1A202C;
    text-align: right;
    font-size: 12.621px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 18.932px;
}
.bounty-footer{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 13.41px;
}
.bounty-footer-header {
    display: flex;
    align-items: center;
    gap: 85.984px;
}
.bounty-footer-text{
    color: #1A202C;
    text-align: right;
    font-size: 11.833px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 18.932px;
}
.bounty-footer-number{
    color: #1A202C;
    font-size: 18.932px;
    font-family:"Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 500;
    line-height: 25.243px;
}
.bounty-footer-range{
    position: relative;
    border-radius: 7887.638px;
    background: #EBEEF4;
    width: 100%;
    height: 6.311px;
}
.bounty-footer-range::after {
    content:  "";
    position: absolute;
    top: 0px;
    right: 0px;
    background: #4C4DDC;
    width: 80%;
    height: 6.311px;
    border-radius: 7887.638px;
}
@media (max-width : 1008px) {
    .bounty-right{
        width: 80%;
        justify-content: center;
        align-items: center;
    }
    .bounty-card{
        width: 80%;
        order: 2;
    }
    .bounty-title{ 
    width: 100%;
    text-align: center;
    }
    .bounty-text{
    width: 100%;
    text-align: center;
    }
    .bounty-footer {align-items: center;}
    .bounty-footer , .bounty-footer-range  {
    width: 100%;
    }
    .bounty-footer-header {
    width: 100%;
    justify-content: space-between;
    }
}

@media (max-width : 580px) {
    .bounty {
        gap: 40px;
        padding: 50px 0px ;
    }
    .bounty-right {
        width: 95%;
    }
    .bounty-contant{
        gap: 10px;
    }
    .bounty-title{ 
        font-size: 30px;
    }
    .bounty-text{
        text-align: center;
        font-size: 16px;
        line-height: 20px;
        font-weight: 300;
    }
    .bounty-card {
        width: 90%;
        height: fit-content;
        gap: 40px;
        padding: 30px 30px;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }
    .bounty-header {
        width: 100%;
        flex-direction: column-reverse;
        gap: 40px;
    }
    .bounty-card-contant{
        justify-content: center;
        align-items: center;
        gap: 15px;
    }
    .bounty-logo {
        width: 49.418px;
        height: 49.418px;
    }
    .bounty-card-title{
        font-size: 25px;
        line-height: 19.719px;
    }
    .bounty-list{
        width: 100%;
        gap: 19.932px;
    }
    .bounty-list-item {
        gap: 10px;
    }
    .bounty-list-item h3 {
        width: 100%;
        font-size: 16px;
        font-weight: 300;
    }
    .bounty-card-text {
        font-size: 16px;
    }
    .bounty-footer {
        gap: 30px;
    }
    .bounty-footer-header {
        flex-direction: column-reverse;
        gap: 20px;
    }
    .bounty-footer-number {
        font-size: 25px;
    }
    .bounty-footer-text {
        font-size: 16px;
        font-weight: 400;
    }

}

.end-cards {
    position: relative;
    width: 100%;
    padding:31px 10px 180px ;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 89.139px;
    /* background-color: #F6F7F9; */
}
.end-cards-container {
    width: 577.433px;
    display: grid;
    grid-template-columns: repeat(2,minmax(0,1fr));
    gap: 28px ;
}
.end-card{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 18.143px;
    width: 276.095px;
    height: 200.366px;
    flex-shrink: 0;
    border-radius: 6.311px;
    border: 0.789px solid #EBEEF4;
    background: #FFF;
    box-shadow: 0px 0.1972106695175171px 0.7888426780700684px 0px rgba(0, 0, 0, 0.04);
    padding: 19px 16px 17px 33px;
}
[number = "2" ]{
    transform: translateY(37px);
}
.end-card-icon {
    width: 50.486px;
    height: 50.486px;
}
.end-card-contant {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 11.833px;
}
.end-card-title {
    color: #1A202C;
    text-align: right;
    font-size: 18.932px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 700;
    line-height: 25.243px;
}
.end-card-text{
    color: #1A202C;
    text-align: right;
    font-size: 12.621px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 18.932px;
}
.end-cards-contant{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 13.41px;
}
.end-cards-title {
    width: 257.952px;
    color: #1A202C;
    text-align: right;
    font-size: 28.398px;
    font-family:  "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 700;
    line-height: 34.078px;
}
.end-cards-text {
    color: #1A202C;
    text-align: right;
    font-size: 12.621px;
    font-family:  "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 18.932px;
}
.end-cards-clip{
    position: absolute;
    width: 100%;
    height: 125%;
    top: 0px;
    left: 0px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 73%);
    background-color: #F6F7F9 ;
    z-index: -1;
}

@media (max-width : 983px) {
    .end-cards-container{
        order: 3;
    }
    .end-cards-contant , .end-cards-contant h1{
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}
@media (max-width : 600px) { 
    .end-cards{
        padding: 31px 10px 80px;
    }
    .end-cards-container {
        width: fit-content;
        gap: 18px ;
    }
    .end-card{
        width: 100%;
        height: fit-content;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .end-cards-text {width: 90%; text-align: center;}
}
@media (max-width : 420px) { 
    .end-cards{
        padding: 61px 13px 60px ;
        gap: 20px;
    }
    .end-cards-clip{
        height: 110%;
        clip-path: polygon(0 0, 100% 0, 100% 93%, 0 90%);
    }
    .end-cards-contant {
        gap:15px;
    }
    .end-cards-title {
        width: 100%;
        font-size: 31px;
        line-height: 40px;
    }
    .end-cards-text {
        width: 99%;
        font-size: 16.5px;
        line-height: 25px;
    }
    .end-cards-container {
        grid-template-columns: repeat(1,minmax(0,100%));
        gap: 25px;
    }
    .end-card {
        width: 100%;
    }
    .end-card-title {
        font-size: 22px;
    }
    .end-card-text {
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
    }
    [number = "2" ]{
        transform: translateY(0px);
    } 
}

/* footer  */
.footer{
    width: 100%;
    background: #121C30;
    padding: 26px 277px 93px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}
.footer-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 400px;
}
.footer-main {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 38.653px;
}
.footer-contant {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 29.187px;
}
.footer-header {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 21.299px;
}
.footer-logo-name {
    width: 100px;
}
.footer-text {
    color: #F6F7F9;
    text-align: right;
    font-size: 10.255px;
    font-family:"Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 16.566px;
}
.footer-lists {
    display: flex;
    align-items: flex-start;
    gap: 75.729px;
}
.footer-list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 9.466px;
    list-style: none;
}
.footer-list li {
    direction: rtl;
    color: #A3ABBA;
    font-size: 12.621px;
    font-family:"Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 18.932px;
}
.footer-logos{
    margin-top: 7px;
    display: flex;
    align-items: flex-start;
    gap: 12.621px;
}
.footer-logo {
    width: 25.243px;
    height: 25.243px;
    border-radius: 7887.638px;
    background: #414959;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12.621px;
    color: #fff;
}
.footer-copyRight {
    width: 100%;
    height: fit-content ;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 13.41px;
    list-style: none;
}
.footer-copyRight li {
    color: #A3ABBA;
    font-size: 11.044px;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 500;
    line-height: 16.566px;
}
.footer-btns{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 67px;
}
.drop-btn {
    width: 31.554px;
    height: 31.554px;
    flex-shrink: 0;
    background-color: #414959;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #FFF;
    border-radius: 10px;
}
.seitch-btn {
    width: 137.259px;
    height: 33.131px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 9px;
    border-radius: 6.311px;
    border: 0.789px solid #414959;
}
.ar-btn {
    width: 63.107px;
    height: 25.243px;
    border-radius: 6.311px;
    background: #414959;
    color: #F6F7F9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11.044px;
    font-family:"Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 13.253px;
}
.en-btn{
    color: #F6F7F9;
    text-align: center;
    font-size: 11.044px;
    font-family:"Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 13.253px;
}
@media (max-width:1220px) {
    .footer {
        width: 100%;
        background: #121C30;
        padding: 26px 0px 93px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .footer-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
       width: 90%;
       gap: 0px;
    }
}
@media (max-width:600px) { 
    .footer {
        padding: 26px 0px;
    }
    .footer-main {
     width: 100%;
     justify-content: center;
     align-items: center;
    }
    .footer-contant {
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .footer-header {
        width: 100%;
    }
    .footer-lists {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }
    .footer li {
    font-size: 9px;
    }
    .footer-logo {
        width: 20.243px;
        height: 20.243px;
        font-size: 8.621px;
        color: #fff;
    }
    .footer-container {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column-reverse;
       width: 90%;
       gap: 40px;
    }
    .footer-iphone-main {
        padding: 100px 0px 140px;
    }
    .footer-phone {
        width: 100%;
        flex-direction: column-reverse;
    }
    .footer-phone .header-title {
        font-size: 30px;
    }
    .footer-phone  .header-text{ 
        width: 100%;
        font-size: 16px;
        color: #5E6473;
    }
    .footer-btns {
        align-items: flex-end;
    }
}