.AboutUs-header {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 63px;
}
.AboutUs-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 22px;
}
.AboutUs-header-tag{
    color:  #4C4DDC;
    text-align: center;
     font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 57.6px;
}
.AboutUs-header-title{
    color: var(--font, #101010);
    text-align: center;
     font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 60.782px; /* 126.628% */
}
.AboutUs-header-text{
    color: var(--font-service, #898C96);
    text-align: center;
     font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
}
.AboutUs-btn{
    direction: rtl;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    background: var(--basis, #4C4DDC);
    width: 132px;
    height: 36px;
    color: #FFF;
    text-decoration: none;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 57.6px; /* 411.429% */
    transition: 0.4s ease-in-out;
}
.AboutUs-btn:hover {
    scale: 1.05;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 1px 1px;
}
 .AboutUs-partners{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
}
.partners-text {
    color: var(--font-service, #898C96);
    text-align: center;
     font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
}
.partners{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 32px;
 }
 .AboutUs-dron{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 26px;
    padding-top: 130px;
    padding-bottom: 175px;
 }
 .dron-content{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    gap: 20px;
 }
 .dron-title{
    direction: rtl;
    color: var(--basis, #4C4DDC);
    text-align: right;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 150.023%; /* 54.008px */
 }
 .dron-text{
    direction: rtl;
    color: var(--font-service, #898C96);
    text-align: right;
  font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 225% */
    width: 553px;
 }
 .dron-btn{
    width: 120px;
    height: 36px;
    border-radius: 7px;
    background: var(--basis, #4C4DDC);
    display: flex;
    justify-content:  center;
    align-items: center;
    color: #FFF;
    text-align: center;
  font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 57.6px; /* 411.429% */
 }
 .osual-dron{
    position: relative;
    padding-bottom: 32px;
 }
 .osual-dron::before{
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #4C4DDC;
    clip-path: polygon(0 70%, 100% 100%, 100% 100%, 0% 100%);
 }
 .osual-dron img{
    width: 482.228px;
    height: 244.078px;
 }
 @media (max-width:1080px) {
    .AboutUs-dron{
        gap: 60px;
        padding-top: 130px;
        padding-bottom: 175px;
     }
     .dron-content{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 30px;
     }
     .dron-title{
        text-align: center;
     }
     .dron-text{
        text-align: center;
     }
 }
 @media (max-width:550px) {
    .dron-content{
        gap: 20px;
     }
    .dron-title{
        font-size: 26px;
        width: 100%;
        padding-inline: 20px;
     }
     .dron-text{
        font-size: 14px;
        width: 100%;
        padding-inline: 20px;
     }
     .osual-dron {
        width: 80%;
     }
     .osual-dron img{
        width: 100%;
        height: auto;
     }
 }
 .AboutUs-cards{
    padding-top: 50px;
    padding-bottom: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    border-top:1px solid #EBEEF4;
 }
 .AboutUs-cards-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
 }
 .AboutUs-title{
    color: var(--background-font, #121C30);
    text-align: center;
  font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 58.207px; /* 161.686% */
}
.AboutUs-text{
    width: 100%;
    padding-inline: 20px;
    color: var(--background-font, #121C30);
    text-align: center;
  font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30.745px; /* 192.156% */
}
.AboutUs-cards-container {
    display: flex;
    flex-wrap: wrap;
    direction: rtl;
    justify-content: center;
    align-items: center;
    gap: 33px;
}
.AboutUs-card{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    width: 235.075px;
    height: 231.92px;
    padding-top: 14px;
    padding-right: 14px;
    border-radius: 6.754px;
    border: 0.844px solid #EBEEF4;
    background: #FFF;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.AboutUs-card-img {
    width: 50.418px;
    height: 50.418px;
}
.AboutUs-card-title{
    color: var(--background-font, #121C30);
    text-align: right;
  font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 29.138px; /* 182.114% */
    margin-bottom: 5px;
}
.AboutUs-card-text {
    width: 194px;
    color: var(--background-font, #121C30);
    text-align: right;
  font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.263px; /* 168.862% */
}
@media (max-width:504px) {
    .AboutUs-cards-container {
        width: 90%;
    }
    .AboutUs-card ,  .AboutUs-cards-container .react-reveal{
        width: 100%;
    }
    .AboutUs-card{
        height: auto;
        padding-bottom: 20px;
    }
    .AboutUs-card-text{
        width: 80%;
    }
    .join-us-contant{
        padding-inline: 20px;
    }
}
.AboutUs-features{
    padding-top: 93px;
    padding-bottom: 93px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
}
.AboutUs-features {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    background: #F5F7F9;
    padding-top: 93px;
    padding-bottom: 93px;
}
.AboutUs-features-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-inline: 20px;
}
.AboutUs-features-title {
    color: var(--background-font, #121C30);
    text-align: center;
  font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 58.207px; /* 161.686% */
}
.AboutUs-features-text {
    color: var(--background-font, #121C30);
    text-align: center;
  font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30.745px; /* 192.156% */
}
.AboutUs-features-card {
    display: flex;
    direction: rtl;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 38px;
    padding-inline: 102px;
}
.FeaturesCard {
    display: flex;
    direction: rtl;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    border-radius: 10.147px;
    background: #FFF;
    border: 2.537px solid #EBEEF4;
    padding: 19.026px 28.557px 22.831px 50px;
    height: 131px;
}
.FeaturesCard-icon {
    width: 50.486px;
    height: 50.486px;
}
.FeaturesCard-content {
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.FeaturesCard-title {
    color: var(--background-font, #121C30);
    text-align: right;
  font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 29.138px; /* 182.114% */
}
.FeaturesCard-text {
    color: var(--background-font, #121C30);
    width: 290px;
    text-align: right;
  font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.263px; /* 168.862% */
}
@media (max-width:450px) {
    .FeaturesCard-content{
        padding-left: 30px;
    }
    .AboutUs-features-title {
        font-size: 26px;
    }
    .AboutUs-features-text {
        font-size: 14px;
        line-height: 30.745px; /* 192.156% */
    }
    .AboutUs-features-card {
        width: 100%;
        gap: 28px;
        padding-inline: 20px;
    }
    .FeaturesCard {
        width: 100%;
        height: 171px;
        padding: 0pc;
        padding-top: 20px;
        padding-bottom: 15px;
        padding-left: 10px;
        padding-right: 5px;
    }
    .FeaturesCard-icon {
        width: 30.486px;
        height:30.486px;
    }
    .FeaturesCard-title {
        font-size: 14px;
        width: 100%;
    }
    .FeaturesCard-text {
        width: 100%;
        font-size: 12px;
    }  
}
.AboutUs-CV {
    padding-top: 210px;
    padding-bottom: 210px;
    display: flex;
    justify-content: center
}
.AboutUs-CV-container{
    position: relative;
    max-width: 1300px;
    min-width: 600px;
    width: 64.2857142857%;
    height: 291px;
    background: var(--background-font, #121C30);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 11px;
}
.AboutUs-CV-container::after{
    content: "";
    position: absolute;
    width: 85px;
    height: 94.444px;
    background-image: url(/public/images/O-right.png);
    background-size: cover;
    background-position: center;
    top: 0px;
    right: 10px;
    z-index: 1;

}
.AboutUs-CV-container::before{
    content: "";
    position: absolute;
    width: 85px;
    height: 94.444px;
    background-image: url(/public/images/O-left.png);
    background-size: cover;
    background-position: center;
    bottom: 20px;
    left: 10px;
    z-index: 1;

}


.AboutUs-CV-title {
    color: #FFF;
    text-align: center;
     font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 57.6px; /* 160% */
    z-index: 2;
}
.AboutUs-CV-text {
    color: #FFF;
    text-align: center;
     font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 57.6px; /* 360% */
    z-index: 2;
}
.AboutUs-CV-btn {
    border-radius: 7px;
    background: var(--basis, #4C4DDC);
    width: 165px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    text-align: center;
     font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 57.6px; /* 411.429% */
    transition: 0.4s all;
    z-index: 2;
}
.AboutUs-CV-btn:hover { 
    scale: 1.05;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

@media (max-width:600px) {
    .AboutUs-CV-container{
        max-width: none;
        min-width: 100px;
        width: 95%;
        height: 321px;
    }
    .AboutUs-CV-container::after,
    .AboutUs-CV-container::before{
        width: 45px;
        height: 64.444px;
    }
    .AboutUs-CV-title {
        font-size: 26px;
        font-weight: 400;
        line-height: 37.6px; /* 160% */
    }
    .AboutUs-CV-text {
        width: 100%;
        padding-inline: 20px;
        font-size: 14px;
        line-height: 37.6px; /* 360% */
        word-spacing: 2px;
    }
}