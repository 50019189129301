.osual-faq-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 60px;
}
.osual-faq-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 22px;
}
.osual-faq-tag{
    color: var(--basis, #4C4DDC);
    text-align: center;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 57.6px; /* 288% */
}
.osual-faq-title{
    color: var(--font, #101010);
    text-align: center;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 60.782px; /* 126.628% */
}
.osual-faq-text {
    width: 90%;
    min-width: 400px;
    color: var(--font-service, #898C96);
    text-align: center;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    direction: rtl;
}
.osual-faq-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    background: var(--basis, #4C4DDC);
    width: 132px;
    height: 36px;
    color: #FFF;
    text-align: center;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 57.6px; /* 411.429% */
}
@media (max-width:450px) {
    .osual-faq-title{
        font-size: 38px;

    }
    .osual-faq-text {
        min-width: 200px;
        font-size: 16px;
        line-height: 26px; /* 150% */
    }
}
.FAQBox{
    position: relative;
    width: 818.278px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 10px;
    padding-inline: 14px;
    padding-top: 11px;
    padding-bottom: 11px;
    border-radius: 7.153px;
    border: 1.431px solid #D7DCE7;
    cursor: pointer;
    transition: 0.1s linear;
}
.FAQBox:hover{
    background-color: #eee;
}
.osual-faq{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
}
.FAQBox-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.FAQBox-header i {
    cursor: pointer;
    width: 20.028px;
    height: 20.028px;
    padding: 5px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1.431px solid #D7DCE7;
    color: #D7DCE7;
    transition: 0.3s all;
}
.FAQBox-header i::after{
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    z-index: 10;
}
.FAQBox-title {
    color: #101010;
    text-align: right;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.FAQBox-text{
    display: none;
    visibility: hidden;
    opacity: 0;
    width: 692.389px;
    color: #101010;
    text-align: right;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 14.306px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 30px;
}
.FAQBox-text span {
    color: var(--First, #4C4DDC);
    text-align: right;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
@-webkit-keyframes FadeText {
    from{
        visibility: hidden;
        opacity: 0;
    }to{
        visibility: visible;
        opacity: 1;
    }
}
@keyframes FadeText {
    from{
        visibility: hidden;
        opacity: 0;
    }to{
        visibility: visible;
        opacity: 1;
    }
}
@-webkit-keyframes FadeTextOut{
    from{
        visibility: visible;
        opacity: 1;
    }to{
        visibility: hidden;
        opacity: 0;
    }
}
@keyframes FadeTextOut{
    from{
        visibility: visible;
        opacity: 1;
    }to{
        visibility: hidden;
        opacity: 0;
    }
}
@media (max-width:850px) {
    .osual-faq{
        width: 90%;
        min-width: 280px;
    }
    .FAQBox{
        width: 100%;
    }
    .FAQBox-text{
        width: 100%;
        font-size: 9;
    }
}
@media (max-width:510px) {
    .FAQBox-title {
        font-size: 12px;
    }
}
