
/* Main class */
.admine{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: #fff;
}

/* first half of the page */
.input-admine{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 40px;
    padding-left: 100px;
    padding-top: 32px;
    width: 50%;
    height: 100vh;
}
.input-admine-nav{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-bottom: 170px;
}
.input-admine-logo{
    width: 48px;
    height: 48px;
}
.input-admine-logo-name{
    width: 100px;
    height: 50px;   
}
.input-admine-form{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}
.input-admine-title{
    color: var(--greyscale-900, #111827);
    text-align: center;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 44.8px */
}

.input-admine-fild{
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 8px;
    background: var(--greyscale-50, #FAFAFA);
    border: none;
}
.input-admine-fild::placeholder{
    color: var(--greyscale-500, #A0AEC0);
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 175%; /* 28px */
}
.input-admine-button{
    display: flex;
    width: 449px;
    height: 56px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--1, #4C4DDC);
    color: var(--others-white, #FFF);
    text-align: center;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 175%; /* 28px */
    border: none;
    cursor: pointer;
}
.another-way{
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
}
.another-way-title{
    width: 100%;
    color: var(--greyscale-600, #718096);
    text-align: center;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 175%; /* 28px */
}
.another-way-btns{
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}
.btn-google , .btn-apple{ 
    display: flex;
    height: 56px;
    width: 50%;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    border: 1px solid var(--greyscale-300, #EEEFF2);
    color: var(--greyscale-900, #111827);
    text-align: center;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: 0.2px;
    background-color: #fff;
    cursor: pointer;
}



/*  !!! not complate this section */
.image-admine{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 50%;
    background-color: #111C30;
    height: 100vh;
}
.image-admine::before{
    content: "";
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background-color: #4C4DDC;
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    z-index: 3;
}
.image-admine-title{
    width: 51%;
    color: #FFF;
    text-align: center;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 41.067px;
    font-style: normal;
    font-weight: 700;
    line-height: 59.733px; /* 145.455% */
    z-index: 5;
}
.Faisaliyah{
    position: absolute;
    width : 900px;
    height: 900px;
    bottom: 0px;
    z-index: 4;
}