
.developers-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 27px ;
    background: rgb(18,28,48);
    background: linear-gradient(153deg, rgba(18,28,48,1) 75%, rgba(76,77,220,1) 75%);
    padding-top: 39px;
    padding-bottom: 39px;
    margin-top: 5px;
}
.developers-title {
    direction: rtl;
    color: #FFF;
    text-align: center;
     font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 57.6px; /* 160% */
}
.developers-text {
    width: 59.5238095238%;
    min-width: 300px;
    color: #FFF;
    text-align: center;
     font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
.developers-project {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 35px;
    padding-bottom: 60px;
}
.developers-project-toggle {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 23px;
    width: 303px;
    height: 52px;
    border-radius: 9999px;
    background: #FFF;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
[activeSide="active"] {
    border-radius: 9999px;
    background: var(--basis, #4C4DDC);
    padding-inline: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #FFF;
    text-align: center;
     font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.2px; /* 120% */
    transition: .4s ease-in-out;
    cursor: pointer;
}
[activeSide="notactive"] { 
    color: #121C30;
    text-align: center;
     font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.2px; /* 120% */
    transition: .2s ease-in-out;
    cursor: pointer;
}
.project-card{
    display: flex;
    background-color: #FFF;
    border-radius: 11.033px;
    width: 308px;
    height: 422.006px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.project-header{
    border-radius: 11.033px 11.033px 0px 0px;
    background-color: #121C30;
    width: 100%;
    height: 29.421px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7.5px;
}
.project-header-title{
    color: #FFF;
    text-align: right;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 12.872px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.307px; /* 150% */
}
.project-header-icon{
    width: 14.71px;
    height: 14.71px;
}
.project-img{
    width: 308px;
    height: 162.734px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap:92px ;
    padding-top: 13px;
}
.like-icon{
    width: 27px;
    height: 27px;
    background-color: #FFF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.like-icon img {
    width: 13.5px;
}
.project-data-type{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 11px;
}
#project-type{
    width: 72.633px;
    height: 28.501px;
    border-radius: 7.355px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.7px;
}
#project-type i {
    font-size: 15px;
}
#project-type h3{
    color: var(--style, #121C30);
    text-align: center;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 12.872px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 19.307px */
}
.project-body{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    padding-top: 15px;
    padding-right: 11px;
}
.project-body-title{
    color: var(--style, #121C30);
    text-align: right;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 17.354px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 26.03px */
    margin-bottom: 9.2px;
}
.project-text{
    color: var(--font-pargraph, #6B7284);
    text-align: right;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 11.043px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 16.565px */
    margin-bottom: 9.3px;
}
.project-numbers{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5.5px;
    margin-bottom: 14px;
}
.project-percentage{
    direction: rtl;
    color: var(--style, #121C30);
    text-align: right;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 12.872px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 19.307px */
}
.project-bar{
    --BackGroundWidth:50%;
    position: relative;
    width: 82.705px;
    height: 5.516px;
    background-color: #EBEEF4;
    border-radius: 3px;
}
.project-bar::after{
    content: "";
    position: absolute;
    right:0px;
    top: 0px;
    width: var(--BackGroundWidth);
    height: 100%;
    background-color: #4C4DDC;
    border-radius: 3px;
}
.project-number{
    direction: rtl;
    color: var(--basis, #4C4DDC);
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 24.824px */   
}
.project-body-data{
    display: flex;
    justify-content: center;
    align-items: center;
    direction: rtl;
    gap: 28.501px;
    width: 285.934px;
    height: 53.325px;
    background-color: #F6F7F9;
    padding-left: 25px;
    border-radius: 8px;
    margin-bottom: 11px;
}
#numbers{
    color: var(--style, #121C30);
    text-align: center;
     font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;

    font-size: 12.872px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 19.307px */
}
.number-type{
    color: var(--font-pargraph, #6B7284);
    text-align: right;
     font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 12.872px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 19.307px */
}
.project-footer{
    display: flex;
    width: 269.385px;
    align-items: center;
    justify-content: space-between;
    direction: rtl;
    margin-top: 10px;
}
.project-dev-name{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5.62px;
}
.project-dev-name img {
    width: 25.241px;


}
.project-footer-text{
    color: #141B2E;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 12.872px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}
.project-footer-text span {
    color:  #6B7284;
}
.developers-cards span:hover {
    border: 0.789px solid #535353;
    background: #adadad;
}
.developers-card {
    display: flex;
    flex-wrap: wrap;
    padding-inline: 30px;
    justify-content: center;
    align-items: center;
    gap: 50px;
}
.developers-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
    border-end-start-radius: 11.033px;
    border-end-end-radius: 11.033px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
.developers-card-header {
    width: 100%;
    height: 29.421px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11.033px 11.033px 0px 0px;
    background: var(--background-font, #121C30);
}
.developers-card-title{
    color: #FFF;
    text-align: right;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 12.872px;
    font-style: normal;
    font-weight: 600;
    line-height: 19.307px; /* 150% */
}
.developers-icon{
    width: 14.71px;
    height: 14.71px;
}
.developers-card-project {
    width: 308px;
    height: 392.585px;
}
#hart{
    font-size: 16px;
    color: red;
    cursor: pointer;
}
#empty-hart{
    cursor: pointer;
    font-size: 16px;
}
@media (max-width: 1100px) {
    .developers-card-container:nth-child(2){
        display: none;
    }
}
@media (max-width: 700px) {
    .developers-card-container:nth-child(3){
        display: none;
    }
}
@media (max-width: 400px) {
    .developers-card-project {
        width: 208px;
        height: 292.585px;
    }
}
