.about {
    width: 100%;
    height: 100vh;
}
.about-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.about-header-container {
    position: relative;
    padding-top: 100px;
    width: 100%;
    padding-bottom: 244px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 27px;
}
.about-header-container::before { 
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #4C4DDC;
    clip-path: polygon(0 91%, 100% 60%, 100% 100%, 0% 100%);
}
.about-header-container::after { 
    content: "";
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #41CE8E;
    clip-path: polygon(0 71%, 0% 100%, 100% 100%);
}
.about-header-tag {
    direction: rtl;
    color: var(--basis, #4C4DDC);
    text-align: center;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 57.6px; /* 288% */
}
.about-main-title {
    width: 69%;
    min-width: 600px;
    direction: rtl;
    color: var(--font, #101010);
    text-align: center;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 60.782px; /* 126.628% */
}
@media (max-width:600px) {
    .about-main-title {
        width: 68.1878306878%;
        min-width: 300px;
        direction: rtl;
        color: var(--font, #101010);
        text-align: center;
        font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 40.782px; /* 126.628% */
    }
}
.about-map {
    padding-top: 156px;
    padding-bottom: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 129px;
    background-image: url(/public/images/world-map\ 1.png);   
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
}
.about-map-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}
.about-map-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 489px;
}
.map-text {
    color: var(--font-service, #898C96);
    text-align: right;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
.about-map-title {
    width: 553px;
    color: var(--font, #101010);
    text-align: right;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 60.782px; /* 126.628% */
    direction: rtl;
}
.about-map-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 14px;
    padding-inline: 20px;
}
.about-map-card {
    width: 232px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 8.68px;
    background-color: #FFF;
    border-radius: 6.754px;
    border: 0.844px solid #EBEEF4;
    background: #FFF;
    box-shadow: 0px 0.25px 1px 0px #0000000A,0px 0.6px 2.5px 0px #00000007,0px 1.15px 4.75px 0px #00000006,0px 2px 8.5px 0px #00000005,0px 3.75px 15.85px 0px #00000004,0px 9px 38px 0px #00000003;
}
.about-map-card-number {
    color: var(--basis, #4C4DDC);
    text-align: right;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 46.237px; /* 128.437% */
}
#sr {
    color: var(--basis, #4C4DDC);
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 46.237px;
}
.about-map-card-type {
    color: var(--background-font, #121C30);
    text-align: right;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.253px; /* 151.581% */
}

@media (max-width:1100px) {
    .about-map-text  , .map-text  {
        width: 300px;
        font-size: 13px;
        line-height: 18px; /* 150% */
    }
    .about-map-title {
        width: 353px;
        font-size: 30px;
        line-height: 40.782px; /* 126.628% */
    }
}
@media (max-width:733px) {
    .about-map {
        padding-top: 90px;
        padding-bottom: 100px;
        gap: 50px;
    }
    .about-map-header {
        flex-direction: column-reverse;
    }
    .about-map-text {
        width: 80%;
    }
    .map-text {
       width: 100%;
       text-align: center;
    }
    .about-map-title {
        width: 80%;
        text-align: center;
        font-size: 30px;
        line-height: 50.782px; /* 126.628% */
    }
}
@media (max-width:516px) {
    .about-map {
        background-position-x: center;
        background-position-y: -10%;
        background-size: 200%;
    }
    .about-map-cards .react-reveal , .about-map-card{
        width: 100%;
    }
    .about-map-card{

    }
}

.about-team {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px; 
    padding-top: 115px;
    padding-bottom: 115px;
    background-color: #F5F7F9 ;
}
.about-team-header {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}
.about-team-title {
    color: var(--font, #101010);
    text-align: center;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 60.782px; /* 168.838% */
}
.about-team-text {
    width: 553px;
    direction: rtl;
    color: var(--font-service, #898C96);
    text-align: center;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}
.about-team-cards {
    width: 80%;
    min-width: 500px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    gap: 32px;
}
.about-team-card {
    width: 210px;
    height: 251px;
    border-radius: 7px;
    background: #FFF;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 21px;
    padding-bottom: 21px;
}
.about-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 210px;
    height: 157px;
    background: var(--background-font, #121C30);
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
}
.about-profile img {
    width: 85px;
    height: 94.444px;
}
.about-team-card-header {
    margin-right: 12px;
    direction: rtl;
}
.member-name {
    color: #121C30;
    text-align: right;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 14px;
    font-style: normal;
    line-height: 24px; /* 171.429% */
    font-weight: 600;
}
.member-department {
    color: var(--font-service, #898C96);
    text-align: right;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
}
.join-us {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;   
    margin-top: 67px;
}
.join-us-contant {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 11px;
}
.join-us-tag {
    color: var(--basis, #4C4DDC);
    text-align: center;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
}
.join-us-title {
    color: var(--font, #101010);
    text-align: center;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
}
.join-us-text {
    color: var(--font-service, #898C96);
    text-align: center;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}
.join-us-btn {
    border-radius: 7px;
    background: var(--basis, #4C4DDC);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    padding: 6px 20px;
}
@media (max-width : 550px) {
.about-team {
    padding-top: 39px;
}
.about-team-text {
    width: 350px;
    font-size: 14px;
}
.about-team-cards {
    width: 80%;
    min-width: 400px;
    gap: 12px;
}
.about-team-card  , .about-profile {
    width: 300px;
}
.join-us {
    gap: 12px;   
}
.join-us-title {
    font-size: 26px;
}
.join-us-text {
    font-size: 12px;
}
}


.about-Partners {
    position: relative;
    padding-top: 70px;
    padding-bottom: 228px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 60px;
}
.about-Partners::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #121C30;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 75%);
}
.about-Partners-title {
    color: #FFF;
    text-align: center;
    font-family: "Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 60.782px; /* 168.838% */
}
.Partners-images {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    row-gap:18px;
    column-gap: 38px;
}
.Partner-img {
    width: 160px;
    height: 160px;
}
@media (max-width:800px) {
    .Partners-images {
        width: 95%;
    }
}
@media (max-width:400px) {
    .about-Partners{
        padding-bottom: 100px;
    }
    .Partner-img {
        width: 60px;
        height: 60px;
    }
}