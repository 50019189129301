
  
/* cards section  */
.cards {
    position: relative;
    width: 100%;
    padding:66px  0px 77px;
    background: #F5F7F9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 22px;
    z-index: 5;
}
.cards-header {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}
.cards-header-title {
    color: #121C30;
    text-align: center;
    font-size: 28.398px;
    font-family:"Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 700;
    line-height: 58.207px;
}
.cards-header-text{
    color: #121C30;
    text-align: center;
    font-size: 16px;
    font-family:"Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;;
    font-weight: 500;
    line-height: 24.253px;
}
.main-cards {
    width: 800px;
    display: grid;
    grid-template-columns: repeat(3 , minmax(0,1fr));
    justify-items: center;
    align-items: center;
    -webkit-column-gap: 180px;
            column-gap: 180px;
}
.ousol-dev{
    position: absolute;
    left: 269px;
    top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3.9px;
    width: 142.781px;
    height: 52.062px;
    border-radius: 30px;
    background-color: #FFF;
}
.ousol-dev p {
    color: var(--Background-Font, #121C30);
    text-align: center;
    font-family:"Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 27px */
}
.ousol-dev img{
    width: 18px;
    height: 20.184px;
    flex-shrink: 0;
}
.white-card {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    gap: 18.932px;
    padding: 19px 23px 28px 32px;
    border-radius: 7.888px;
    border: 1.012px solid #EBEEF4;
    background: #FFF;
    box-shadow: 0px 0.2529355585575104px 1.0117422342300415px 0px rgba(0, 0, 0, 0.04);
}
.white-card-icon {
    width: 50.486px;
    height: 50.486px;
}
.white-card-contant {
    display: flex;
    width: 177.49px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.white-card-title {
    color: #121C30;
    text-align: right;
    font-size: 18px;
    font-family: "Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-weight: 800;
    font-style: normal;
    line-height: 29.138px;
}
.white-card-text {
    color: var(--Secondary-Darker, #121C30);
    text-align: right;
    font-family: "Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.282px; /* 202.348% */
    direction: rtl;
}
.Proj-card img{
    width: 451.048px;
    height: 498.584px;
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
}
@media (max-width : 1000px) {
    .main-cards {
        width: 80%;
        display: grid;
        grid-template-columns: repeat(1 , minmax(0,1fr));
        justify-items: center;
        align-items: center;
        gap: 2%;
    }
    .white-card-contant {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
    }

}
@media (max-width : 550px) { 
    .Proj-card img{
        width: 301.048px;
        height: 350px;
        -webkit-transform: translateX(50px);
                transform: translateX(50px);
    }
    .ousol-dev{
        left: 239px;
        top: 20px;
        width: 102.781px;
        height: 32.062px;
        border-radius: 30px;
    }
    .ousol-dev p {
        font-size: 12px;
    }
    .ousol-dev img{
        width: 12px;
        height: 14.184px;
    }
}
@media (max-width : 400px) {
    .cards {
        padding:35px  0px 40px;     
        gap: 22px;
    }
    .Proj-card img{
        width: 250px;
        height: 353.584px;
        -webkit-transform: translateX(20px);
                transform: translateX(20px);
    }
    .main-cards {
        width: 98%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    .main-cards .react-reveal {
        display: flex;
        justify-content: center;
        align-items: center;
    } 
    .cards-header {
       width: 100%;
       gap: 10px;
    }
    .cards-header-title {
        font-size: 30px;
    }
    .cards-header-text {
        width: 93%;
        font-size: 16px;
    }
    .Proj-card  ,  .Proj-card  img{
        width: 94%;
        order: 3;
    }



    .white-card {
        padding:20px 10px;
        width: 95% ;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .white-card-contant {
        width: 100%;
    }
    .white-card-title {
        font-weight: 700;
    }
    .white-card-text {
        font-weight: 400;
    }
}


.haeder-nav-menus , .haeder-nav-menus  a {
    color: var(--background, #121C30);
    font-size: 16px;
    font-family:"Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    line-height: 24.501px;
    text-decoration: none;
}






input[type="range"] {
    -webkit-appearance: none;
            appearance: none;
    /* margin-right: 15px; */
    width: 100%;
    height: 7px;
    background: #EBEEF4;
    border-radius: 5px;
    background-image: linear-gradient(#4C4DDC, #4C4DDC);
    background-size: 50% 100%;
    background-repeat: no-repeat;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  #range-three{
    background-size: 55% 100%;
  }
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 10px;
    width:10px;
    padding: 5px;
    border-radius: 50%;
    background: #FFF;
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
    -webkit-transition: background .3s ease-in-out;
    transition: background .3s ease-in-out;
    border:5px #4C4DDC solid;
  }
  input[type="range"]::fou
  input[type=range]::-webkit-slider-runnable-track  {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  } 


  

 
  input[type="range"]:focus::-moz-range-thumb{
    outline: 3px solid #ff3300;
    outline-offset: 0.125rem;
  }
  .header-read-more {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #121C30;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .read-more {
    color: #FFF;
    text-align: center;
    font-family:"Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30.782px;
    padding:10px 20px;
  }
@media (max-width: 400px) {
    .read-more {
        color: #FFF;
        text-align: center;
        font-family:"Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20.782px;
      }
}
[Linkactive="active"]{
    position: relative;
}
[Linkactive="active"]::after {
    content: "";
   position: absolute;
   bottom: 0px;
   opacity: 0;
   left: 0px;
   width: 100%;
   height: 4px;
   background-color: #4C4DDC;
   border-radius: 4px;
   -webkit-animation:FadeBot .5s .5s;
           animation:FadeBot .5s .5s;
   -webkit-animation-fill-mode: both;
           animation-fill-mode: both;
}
@-webkit-keyframes FadeBot {
    from{
        bottom: 0px;
        opacity: 0;  
    }
    to{
        bottom: -10px;
        opacity: 1;
    }
}
@keyframes FadeBot {
    from{
        bottom: 0px;
        opacity: 0;  
    }
    to{
        bottom: -10px;
        opacity: 1;
    }
}
.mobile-view{
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 100vh;
    background-color:#121C2F;
    z-index: 10 !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 20px;
    visibility: visible !important;
}
.mobile-view * {
    visibility: visible !important;
}
.mobile-view-nav{
    padding-inline: 20px;
    display: flex;
    width: 100%;
    height: 75px;
    justify-content: space-between;
    align-items: center;
    border-bottom: #eee 1px solid;
    z-index: 10 !important;
}
.mobile-nav-logo{
    width: 40px;
    z-index: 10 !important;
    height: 40px;
}
.mobile-view-nav i {
    font-size: 24px;
    color: #fff;
    z-index: 10 !important;

}
.mobile-view-body{
    display: flex;
    flex-direction: column;
    direction: rtl;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    padding-inline: 20px;
    z-index: 10 !important;
}
.mobile-view-body a{
    text-decoration: none;
    font-size: 30px;
    color: #FFF;
    font-family:"Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    z-index: 10 !important;
}
.mobile-view-footer{
    margin-top: 30%;
    padding-top: 20px;
    width: 100%;
    border-top: #eee 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    z-index: 10 !important;
}
.mobil-btn{
    width: 90%;
    height: auto;
    border-radius: 5px;
    border: 1px #4C4DDC solid;
    text-align: center;
    text-decoration: none;
    font-size: 24px;
    color: #FFF;
    font-family:"Plus Jakarta SansVariable","Noto Sans ArabicVariable",--apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,sans-serif;
    z-index: 10 !important;

}
[close=true] .header-read-more{
    display: none;
}
[close=true]{
        overflow-y: hidden;
        height: 100vh;
}
[close=true] * {
    /* -webkit-animation: FadeIn 0.3s ease-in;
            animation: FadeIn 0.3s ease-in; */
            visibility:visible;
    /* -webkit-animation-fill-mode: both; */
            /* animation-fill-mode: both; */
}
[close=false]{
    -webkit-animation: FadeOut 0.3s ease-in;
            animation: FadeOut 0.3s ease-in;
    height: 100%;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
}
@-webkit-keyframes FadeIn {
    from {
        visibility:visible;
    }to{
        visibility: hidden;
    }
}
@keyframes FadeIn {
    from {
        visibility:visible;
    }to{
        visibility: hidden;
    }
}
@-webkit-keyframes FadeOut {
    from {
        visibility: hidden;
        
    }to{
        visibility:visible;
    }
}
@keyframes FadeOut {
    from {
        visibility: hidden;
        
    }to{
        visibility:visible;
    }
}
.qrcode-container{
    
}
@media (max-width:1000px) {
    .qrcode-container{
        display: none;
    }
}
[showNav="true"] {
    top: 0px;
    left: 0px;
    position: fixed;
}
[showNav="false"] {
    position: relative;
    top: 0px;
    left: 0px;
}
.logo-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
[logo ="logo"]{
    width: 37.864px;
    height: 37.864px;
}
[logo ="name"]{
    width: 82px;
    height: 39px;
}
